import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { constants } from '@/constants';
const SlippageContext = createContext(null);
export const SlippageProvider = ({ children }) => {
    const [slippage, setSlippage] = useState(constants.INITIAL_SLIPPAGE);
    const [useInput, setUseInput] = useState(false);
    return (_jsx(_Fragment, { children: _jsx(SlippageContext.Provider, { value: { slippage, setSlippage, useInput, setUseInput }, children: children }) }));
};
export const useSlippageContext = () => {
    const context = useContext(SlippageContext);
    if (!context) {
        throw new Error('useSlippageContext must be used within a SlippageProvider');
    }
    return context;
};
