import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
const IconOrDefaultIcon = ({ tokenUrl, tokenSymbol, children, isMaincard = false, }) => {
    const [isError, setIsError] = useState(false);
    return isError || !tokenUrl ? (_jsx("div", { className: `flex items-center justify-center border border-neutral-800 dark:border-neutral-700 rounded-100 bg-secondary-10 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-0 ${isMaincard ? 'text-[32px] leading-[38.4px]' : 'text-[24px] leading-[28.8px]'} font-semibold`, style: {
            width: `${isMaincard ? '40px' : '30px'}`,
            height: `${isMaincard ? '40px' : '30px'}`,
        }, children: tokenSymbol.charAt(0) })) : (_jsx("div", { onError: () => setIsError(true), children: children }));
};
export default IconOrDefaultIcon;
