import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
const SwapNetworksContext = createContext(null);
export const SwapNetworksProvider = ({ children }) => {
    const [swapNetworks, setSwapNetworks] = useState([]);
    return (_jsx(_Fragment, { children: _jsx(SwapNetworksContext.Provider, { value: { swapNetworks, setSwapNetworks }, children: children }) }));
};
export const useSwapNetworksContext = () => {
    const context = useContext(SwapNetworksContext);
    if (!context) {
        throw new Error('useSwapNetworksContext must be used within a SwapNetworksProvider');
    }
    return context;
};
