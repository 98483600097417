import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useAccount } from 'wagmi';
import Loading from '@/components/Loading';
import { getImage, shortenAddress } from '@/utils';
import AccountContent from './AccountContent';
import { WALLETS } from '@/constants';
const AccountButton = () => {
    const { address, connector } = useAccount();
    const [showMenu, setShowMenu] = useState(false);
    // TODO for walletconnect
    //const { walletImagePath } = useWalletImagePath()
    return (_jsxs("div", { className: "account-button relative", children: [_jsxs("button", { className: "z-50 flex justify-center items-center bg-neutral-0 dark:bg-secondary-900 dark:text-neutral-0 dark:border-neutral-700 border border-neutral-800 header-button text-clip mobile:px-2", type: "button", onClick: () => {
                    setShowMenu(true);
                }, "aria-label": 'Show "Account" menu', children: [connector ? (
                    // connector.name === "WalletConnect" ? (
                    //   walletImagePath && (
                    //     <img
                    //       className="h-6 inline"
                    //       src={getImage(walletImagePath)}
                    //       alt="Wallet Image"
                    //       style={{ width: '1.813rem', height: '1.813rem' }}
                    //     />
                    //   )
                    // ) : (
                    _jsx("img", { className: "h-6 inline", src: getImage('wallet/' +
                            WALLETS.find((wallet) => wallet.name === connector.name)
                                .imgTransparent), alt: "Wallet Image", style: { width: '1.813rem', height: '1.813rem' } })) : (
                    //)
                    _jsx(Loading, {})), _jsx("span", { className: "pl-2 text-label_l", children: shortenAddress(address, 4) })] }), showMenu && (_jsxs(_Fragment, { children: [_jsx("button", { onClick: () => {
                            setShowMenu(false);
                        }, className: "cursor-pointer fixed w-full h-full modal-background z-50 top-0 left-0", "aria-label": 'Close "Account" menu' }), _jsx(AccountContent, {})] }))] }));
};
export default AccountButton;
