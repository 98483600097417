import { jsx as _jsx } from "react/jsx-runtime";
import { constants } from '@/constants';
import { createContext, useContext, useReducer, useMemo, useEffect, } from 'react';
export var TradeType;
(function (TradeType) {
    TradeType[TradeType["UPWARD"] = 0] = "UPWARD";
    TradeType[TradeType["DOWNWARD"] = 1] = "DOWNWARD";
    TradeType[TradeType["CCTP"] = 2] = "CCTP";
})(TradeType || (TradeType = {}));
const initialState = {
    txList: [],
    latestTx: null,
};
const txInfoReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TX': {
            const newTxList = [...state.txList, action.payload];
            return {
                ...state,
                txList: newTxList,
                latestTx: action.payload,
            };
        }
        case 'REMOVE_TX':
            return {
                ...state,
                txList: state.txList.filter((tx) => tx.txhash !== action.payload || tx.id !== action.payload),
            };
        case 'REMOVE_COMPLETED':
            return {
                ...state,
                txList: state.txList.filter((tx) => tx.status !== constants.DB_STATUS_COMPLETED),
            };
        case 'CLEAR_ALL_TX':
            return {
                ...state,
                txList: [],
            };
        case 'UPDATE_TX_INFO': {
            let updatedTx = null;
            const updatedTxList = state.txList.map((tx) => {
                if (tx.txhash === action.payload.idOrTxHash ||
                    tx.id === action.payload.idOrTxHash) {
                    updatedTx = { ...tx, ...action.payload.updates };
                    return updatedTx;
                }
                return tx;
            });
            return {
                ...state,
                txList: [...updatedTxList],
                latestTx: updatedTx || state.latestTx,
            };
        }
        default:
            return state;
    }
};
const CurrentTxInfoContext = createContext(undefined);
export const CurrentTxInfoProvider = ({ children, }) => {
    const [state, dispatch] = useReducer(txInfoReducer, initialState);
    const addTx = (tx) => {
        const newTx = {
            ...tx,
            timestamp: new Date().getTime(),
        };
        dispatch({ type: 'ADD_TX', payload: newTx });
    };
    const removeTx = (idOrTxHash) => {
        dispatch({ type: 'REMOVE_TX', payload: idOrTxHash });
    };
    const removeCompletedTx = () => {
        dispatch({ type: 'REMOVE_COMPLETED' });
    };
    const clearAllTx = () => {
        dispatch({ type: 'CLEAR_ALL_TX' });
    };
    const updateTxInfo = (idOrTxHash, updates) => {
        dispatch({ type: 'UPDATE_TX_INFO', payload: { idOrTxHash, updates } });
    };
    const getTxByHash = (txHash) => {
        return state.txList.find((tx) => tx.txhash === txHash);
    };
    const hasProgressingTx = useMemo(() => {
        return state.txList.some((tx) => tx.status === constants.DB_STATUS_IN_PROGRESS);
    }, [state.txList]);
    return (_jsx(CurrentTxInfoContext.Provider, { value: {
            txList: state.txList,
            latestTx: state.latestTx,
            addTx,
            removeTx,
            removeCompletedTx,
            clearAllTx,
            updateTxInfo,
            getTxByHash,
            hasProgressingTx,
        }, children: children }));
};
export const useCurrentTxInfo = () => {
    const context = useContext(CurrentTxInfoContext);
    if (!context) {
        throw new Error('useCurrentTxInfo must be used within a CurrentTxInfoProvider');
    }
    return context;
};
export const useCurrentTxStatusMonitor = (callback) => {
    const { txList, removeCompletedTx } = useCurrentTxInfo();
    useEffect(() => {
        const changedTx = txList.find((tx) => tx.status === constants.DB_STATUS_COMPLETED);
        if (changedTx) {
            removeCompletedTx();
            callback();
        }
    }, [txList]);
};
