import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';
import history from '@/assets/new-icon/header/history.svg';
import historyDark from '@/assets/new-icon/header/history-dark.svg';
import { useIsDarkModeContext } from '@/context';
import { isTabletDevice } from '@/utils';
import { useCurrentTxInfo } from '@/context/CurrentTxInfoContext';
import { useCurrentSwapTxInfo } from '@/context/swap/CurrentSwapTxInfoContext';
const HistoryButton = () => {
    const location = useLocation();
    const { isDarkMode } = useIsDarkModeContext();
    const { isConnected } = useAccount();
    const { hasProgressingTx } = useCurrentTxInfo();
    const { hasProgressingTx: hasProgressingSwapTx } = useCurrentSwapTxInfo();
    return (_jsx("div", { className: "flex items-center", children: isConnected && (_jsx("div", { children: _jsxs(Link, { to: "/history", className: `${isTabletDevice() ? 'w-9' : 'w-[105px] px-3 py-2'} h-9 z-50 flex justify-center items-center bg-neutral-0 dark:bg-secondary-900 dark:text-neutral-0 ${location.pathname !== '/history'
                    ? 'border border-neutral-800'
                    : 'border-2 border-primary-500'} ${!hasProgressingTx && !hasProgressingSwapTx
                    ? 'dark:border-neutral-700'
                    : 'dark:border-primary-400'} rounded-full`, "aria-label": 'Move to "History" page', children: [!hasProgressingTx && !hasProgressingSwapTx ? (_jsx("img", { className: "inline", src: isDarkMode ? historyDark : history, alt: "History Icon", style: { width: '20px', height: '20px' } })) : (_jsx("div", { className: "loader-modal" })), !isTabletDevice() && (_jsx("span", { className: "pl-1 text-label_l font-medium", children: "History" }))] }) })) }));
};
export default HistoryButton;
