import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './tippy.css';
import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';
import question from '@/assets/new-icon/main/question.svg';
import questionDark from '@/assets/new-icon/main/question-dark.svg';
import { useIsDarkModeContext } from '@/context';
const DetailRowWithIcon = ({ title, icon, iconAlt, tooltipContentIcon, left, right, tooltipContent, className = '', canPopup = true, }) => {
    const { isDarkMode } = useIsDarkModeContext();
    return (_jsx("div", { className: `${className}`, children: _jsxs("div", { className: "flex flex-row w-full flex-nowrap my-2 justify-between text-label_m font-medium", children: [_jsxs("div", { className: "text-left", children: [icon && (_jsx("img", { src: icon, alt: iconAlt, style: {
                                width: '1.5rem',
                                height: '1.5rem',
                            }, className: "h-6 inline-block mr-1" })), left, title && _jsx("span", { children: title }), canPopup && (_jsx(Tippy, { placement: "bottom", animation: "fade", interactive: true, arrow: true, content: _jsxs("div", { className: "rounded-8 text-neutral-800 dark:text-neutral-0", children: [_jsx("img", { src: tooltipContentIcon, alt: "Tooltip Content Icon", className: "absolute h-5 left-4 top-0 -translate-y-1/2", width: "40", height: "20" }), _jsx("div", { children: tooltipContent })] }), children: _jsx("img", { src: isDarkMode ? questionDark : question, alt: "Show Help", width: "14", height: "14", className: "inline-block ml-1 mb-0.5 hover:cursor-help", tabIndex: 0 }) }))] }), _jsx("div", { className: "text-right ml-2", children: right })] }) }));
};
export default DetailRowWithIcon;
