// react
import { useEffect, useRef } from 'react';
// components
import { SwapProcessingStatus } from '@/components/swap/ProcessingModalContent';
// context
import { useCurrentSwapTxInfo } from '@/context/swap/CurrentSwapTxInfoContext';
export const useTracking = (tracking) => {
    const { txList, updateTxInfo, removeTx } = useCurrentSwapTxInfo();
    const isTracking = useRef(new Map());
    const trackingProcess = async (chainId, txHash) => {
        try {
            const isCompleted = await tracking(chainId, txHash, false);
            if (!isCompleted) {
                await new Promise((f) => setTimeout(f, 5000));
            }
            return isCompleted;
        }
        catch (error) {
            console.log(error);
        }
        return false;
    };
    useEffect(() => {
        txList.forEach((tx) => {
            if (tx.processingStatus === SwapProcessingStatus.SWAP_PROCESSING &&
                !tx.isProcessing &&
                !tx.isTracking &&
                (!isTracking.current.has(tx.fromTxhash) ||
                    !isTracking.current.get(tx.fromTxhash))) {
                isTracking.current.set(tx.fromTxhash, true);
                trackingProcess(tx.fromNetworkId, tx.fromTxhash).then(() => {
                    isTracking.current.set(tx.fromTxhash, false);
                    updateTxInfo(tx.fromTxhash, {
                        isTracking: false,
                    });
                });
                updateTxInfo(tx.fromTxhash, {
                    isTracking: true,
                });
            }
            else if (tx.processingStatus === SwapProcessingStatus.NOTHING) {
                removeTx(tx.fromTxhash);
            }
        });
    }, [txList]);
};
