import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const PathChangeContext = createContext(undefined);
export const PathChangeProvider = ({ children, }) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [previousPath, setPreviousPath] = useState('');
    const [pathChanged, setPathChanged] = useState(false);
    useEffect(() => {
        if (currentPath !== location.pathname) {
            setPreviousPath(currentPath);
            setCurrentPath(location.pathname);
            setPathChanged(true);
        }
        else {
            setPathChanged(false);
        }
    }, [location.pathname, currentPath]);
    return (_jsx(PathChangeContext.Provider, { value: { currentPath, previousPath, pathChanged }, children: children }));
};
export const usePathChangeContext = () => {
    const context = useContext(PathChangeContext);
    if (!context) {
        throw new Error('usePathChangeContext must be used within a PathChangeProvider');
    }
    return context;
};
