import { getWalletClient } from '@wagmi/core';
import { providers } from 'ethers';
import { wagmiConfig } from '@/wagmiConfig';
export function walletClientToSigner(walletClient, chain) {
    const { account, transport } = walletClient;
    let provider;
    if (chain) {
        const network = {
            chainId: chain.id,
            name: chain.name,
            ensAddress: chain.contracts?.ensRegistry?.address,
        };
        provider = new providers.Web3Provider(transport, network);
    }
    else {
        provider = new providers.Web3Provider(transport);
    }
    const signer = provider.getSigner(account.address);
    return signer;
}
/** Action to convert a viem Wallet Client to an ethers.js Signer. */
export async function getEthersSigner(chain) {
    const walletClient = await getWalletClient(wagmiConfig, chain ? { chainId: chain.id } : {});
    if (!walletClient)
        return undefined;
    return walletClientToSigner(walletClient, chain);
}
