import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAccount } from 'wagmi';
import AccountButton from '@/domain/header/AccountButton';
import { isMobileDevice } from '@/utils';
import HistoryButton from './HistoryButton';
const HeaderButtons = ({ isWalletModalOpen, setIsWalletModalOpen }) => {
    const { isConnected } = useAccount();
    return (_jsxs("div", { className: "header-buttons flex items-center gap-x-4", children: [!isMobileDevice() && _jsx(HistoryButton, {}), !isConnected && (_jsx("div", { children: _jsx("button", { className: "button h-11 bg-primary-500 hover:bg-primary-600 dark:hover:bg-primary-400 header-button relative mobile:px-4 dark:text-neutral-800", type: "button", onClick: () => setIsWalletModalOpen(!isWalletModalOpen), children: _jsx("span", { children: "Connect Wallet" }) }) })), isConnected && _jsx(AccountButton, {})] }));
};
export default HeaderButtons;
