// react
import { useRef, useCallback, useEffect, useState } from 'react';
// ethers
import { ethers } from 'ethers';
// wagmi
import { wagmiConfig } from '@/wagmiConfig';
// context
import { useSwapSelectedRouteContext } from '@/context/swap/SelectedRouteContext';
// sdk
import { PheasantSDK, WalletOrProvider, NetworkManagerSwap, getHealthyRpc, } from '@pheasant-network/pheasant-sdk';
// utils
import { getEthersSigner } from '@/utils/ethers';
const usePheasantSwap = (isConnected) => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const pheasantSwap = useRef(null);
    const networkManager = useRef(null);
    const [isSwapReady, setIsSwapReady] = useState(false);
    const { selectedFromChain } = useSwapSelectedRouteContext();
    const initializeSDK = useCallback(async (isConnected) => {
        setIsSwapReady(false);
        const walletOrProvider = isConnected
            ? WalletOrProvider.init(await getEthersSigner())
            : undefined;
        const sdk = await PheasantSDK.initSwap({
            lifi: {
                integrator: process.env.REACT_APP_LIFI_INTEGRATOR || '',
            },
            squid: {
                integratorId: process.env.REACT_APP_SQUID_INTEGRATOR || '',
            },
            fee: {
                integratorFee: Number(process.env.REACT_APP_INTEGRATOR_FEE || '0'),
                integratorAddress: process.env.REACT_APP_INTEGRATOR_ADDRESS || '',
            },
        }, walletOrProvider);
        pheasantSwap.current = sdk;
        setIsSwapReady(true);
    }, []);
    const updateWalletProvider = async (chainId, rpcUrls) => {
        const chain = wagmiConfig.chains.find((chain) => chain.id === Number(chainId));
        let url = chain.rpcUrls.default.http[0];
        if (rpcUrls && rpcUrls.length > 0) {
            try {
                url = await getHealthyRpc(rpcUrls);
            }
            catch (err) {
                console.log(err);
            }
        }
        const walletOrProvider = WalletOrProvider.init(new ethers.providers.JsonRpcProvider(url, 'any'));
        pheasantSwap.current.walletOrProvider = walletOrProvider;
    };
    useEffect(() => {
        if (isMainnet && isSwapReady && isConnected && selectedFromChain?.chainId) {
            updateWalletProvider(selectedFromChain.chainId, selectedFromChain.rpcUrls);
        }
    }, [isSwapReady, selectedFromChain]);
    useEffect(() => {
        if (isMainnet)
            initializeSDK(isConnected);
    }, [initializeSDK, isConnected]);
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'local') {
            networkManager.current = NetworkManagerSwap.init('dev');
        }
        else {
            networkManager.current = NetworkManagerSwap.init(process.env.REACT_APP_ENV);
        }
    }, []);
    return { pheasantSwap, isSwapReady, networkManager };
};
export default usePheasantSwap;
