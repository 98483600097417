import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import back from '@/assets/new-icon/history/back.svg';
import backDark from '@/assets/new-icon/history/back-dark.svg';
import { useIsDarkModeContext } from '@/context';
import { usePathChangeContext } from '@/context/PathChangeContext';
const BackButton = () => {
    const navigate = useNavigate();
    const { previousPath } = usePathChangeContext();
    const { isDarkMode } = useIsDarkModeContext();
    const validRoutes = ['/', '/swap'];
    const canGoBack = validRoutes.includes(previousPath);
    if (!canGoBack)
        return _jsx("div", {});
    return (_jsx("button", { onClick: () => navigate(-1), className: "flex items-center", "aria-label": "Back", children: _jsx("img", { className: "inline", src: isDarkMode ? backDark : back, alt: "Back Icon", style: { width: '24px', height: '24px' } }) }));
};
export default BackButton;
