import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { createContext, useContext, useState } from 'react';
const SelectedRouteContext = createContext({
    selectedFromChain: null,
    setSelectedFromChain: () => { },
    selectedFromToken: null,
    setSelectedFromToken: () => { },
    selectedToChain: null,
    setSelectedToChain: () => { },
    selectedToToken: null,
    setSelectedToToken: () => { },
    fromAmount: '',
    setFromAmount: () => { },
    fromAmountUSD: '',
    setFromAmountUSD: () => { },
    toAmount: '',
    setToAmount: () => { },
    toAmountUSD: '',
    setToAmountUSD: () => { },
    recipient: '',
    setRecipient: () => { },
    totalFeeUSD: '',
    setTotalFeeUSD: () => { },
    swapFeeUSD: '',
    setSwapFeeUSD: () => { },
    relayerFee: '',
    setRelayerFee: () => { },
    relayerFeeUSD: '',
    setRelayerFeeUSD: () => { },
    protocolFee: '',
    setProtocolFee: () => { },
    protocolFeeUSD: '',
    setProtocolFeeUSD: () => { },
    estimatedTime: '',
    setEstimatedTime: () => { },
    canSwap: false,
    setCanSwap: () => { },
    isToRoute: false,
    setIsToRoute: () => { },
    isFetchingRoute: false,
    setIsFetchingRoute: () => { },
    routeError: false,
    setRouteError: () => { },
    prepareRouteError: false,
    setPrepareRouteError: () => { },
    route: null,
    setRoute: () => { },
    needApprove: false,
    setNeedApprove: () => { },
    approvedTxLink: '',
    setApprovedTxLink: () => { },
    transactionHash: '',
    setTransactionHash: () => { },
    processingStatus: null,
    setProcessingStatus: () => { },
});
export const SwapSelectedRouteProvider = ({ children }) => {
    const [selectedFromChain, setSelectedFromChain] = useState(null);
    const [selectedFromToken, setSelectedFromToken] = useState(null);
    const [selectedToChain, setSelectedToChain] = useState(null);
    const [selectedToToken, setSelectedToToken] = useState(null);
    const [fromAmount, setFromAmount] = useState('');
    const [fromAmountUSD, setFromAmountUSD] = useState('0');
    const [toAmount, setToAmount] = useState('');
    const [toAmountUSD, setToAmountUSD] = useState('0');
    const [recipient, setRecipient] = useState('');
    const [totalFeeUSD, setTotalFeeUSD] = useState('0');
    const [swapFeeUSD, setSwapFeeUSD] = useState('0');
    const [relayerFee, setRelayerFee] = useState('0');
    const [relayerFeeUSD, setRelayerFeeUSD] = useState('0');
    const [protocolFee, setProtocolFee] = useState('0');
    const [protocolFeeUSD, setProtocolFeeUSD] = useState('0');
    const [estimatedTime, setEstimatedTime] = useState('0');
    const [canSwap, setCanSwap] = useState(false);
    const [isToRoute, setIsToRoute] = useState(false);
    const [isFetchingRoute, setIsFetchingRoute] = useState(false);
    const [routeError, setRouteError] = useState(false);
    const [prepareRouteError, setPrepareRouteError] = useState(false);
    const [route, setRoute] = useState(null);
    const [needApprove, setNeedApprove] = useState(false);
    const [approvedTxLink, setApprovedTxLink] = useState('');
    const [transactionHash, setTransactionHash] = useState('');
    const [processingStatus, setProcessingStatus] = useState(null);
    const selectedRouteContextValue = {
        selectedFromChain,
        setSelectedFromChain,
        selectedFromToken,
        setSelectedFromToken,
        selectedToChain,
        setSelectedToChain,
        selectedToToken,
        setSelectedToToken,
        fromAmount,
        setFromAmount,
        fromAmountUSD,
        setFromAmountUSD,
        toAmount,
        setToAmount,
        toAmountUSD,
        setToAmountUSD,
        recipient,
        setRecipient,
        totalFeeUSD,
        setTotalFeeUSD,
        swapFeeUSD,
        setSwapFeeUSD,
        relayerFee,
        setRelayerFee,
        relayerFeeUSD,
        setRelayerFeeUSD,
        protocolFee,
        setProtocolFee,
        protocolFeeUSD,
        setProtocolFeeUSD,
        estimatedTime,
        setEstimatedTime,
        canSwap,
        setCanSwap,
        isToRoute,
        setIsToRoute,
        isFetchingRoute,
        setIsFetchingRoute,
        routeError,
        setRouteError,
        prepareRouteError,
        setPrepareRouteError,
        route,
        setRoute,
        needApprove,
        setNeedApprove,
        approvedTxLink,
        setApprovedTxLink,
        transactionHash,
        setTransactionHash,
        processingStatus,
        setProcessingStatus,
    };
    return (_jsx(_Fragment, { children: _jsx(SelectedRouteContext.Provider, { value: selectedRouteContextValue, children: children }) }));
};
export const useSwapSelectedRouteContext = () => {
    return useContext(SelectedRouteContext);
};
