import { currentNetworkList, Token } from '@pheasant-network/pheasant-sdk';
import eth from '@/assets/new-icon/token/ethereum.svg';
import usdc from '@/assets/new-icon/token/usdc.svg';
export const constants = {
    TRANSACTION_FEE: 0,
    DISPLAY_DIGIT: 7,
    VALUE: 1,
    METAMASK_GENERAL_ERROR: 'The interaction was rejected for some reasons.',
    ZERO_INPUT_ERROR: 'Please input an amount greater than 0.',
    TOO_LOW_AMOUNT_ERROR: 'More than 0.005 ETH needed.',
    TOO_HIGH_GAS_ERROR: 'Temporary gas price is extremely high. The fee exceeds the trade limit. Please try again later.',
    NOT_SUPPORTED_NETWORK_ERROR: 'Not supported Network.',
    NO_TXHASH_ERROR: 'Please input the txhash.',
    INVALID_AMOUNT_ERROR: 'The amount is different from the transaction information entered.',
    INVALID_NETWORK_SELECTION_ERROR: 'Invalid network selection. Please change network manually',
    ADDRESS_EXPLANATION: 'Assets can be sent to a different address on the destination chain.',
    FEE_EXPLANATION: 'Relayer fee is the trading fee. Protocol fee is kept in treasury.',
    SWAP_FEE_EXPLANATION: 'Swap fee is for aggregators. Relayer fee is the trading fee. Protocol fee is kept in treasury.',
    NOT_SELECT_NETWORK: 'No network selected.',
    NOT_ENOUGH_BOND_AMOUNT: "This relayer doesn't have enough bond. Pls ask in our discord channel!",
    INSUFFICIENT_ERROR: 'Insufficient funds',
    SWAP_LACK_OF_GAS_FEE_ERROR: 'This swap may fail due to lack of gas fee.',
    ZERO_FEE_ERROR: 'invalid zero fee. please wait for fee calculation or reload page',
    UNKNOWN_ERROR: 'Please reload the screen due to a temporary error.',
    NOT_CONNECTED_ERROR: 'Please connect a wallet first.',
    MINIMUM_L1_BALANCE_ERROR: 'You need have more than 0.001 ETH balance on Ethereum mainnet for using the testnet bridging.',
    NOT_NATIVE_NETWORK_NAMES: [
        'Polygon',
        'Mumbai',
        'Amoy',
        'Mantle',
        'Mantle(T)',
        'X Layer(T)',
        'X Layer(T)',
    ],
    NOT_NATIVE_NETWORK_SYMBOLS: {
        Polygon: 'MATIC',
        Mumbai: 'MATIC',
        Amoy: 'MATIC',
        Mantle: 'MNT',
        'Mantle(T)': 'MNT',
        'X Layer(T)': 'OKB',
        'X Layer': 'OKB',
    },
    NEWTRADE_ETH_GAS: 259000,
    NEWTRADE_WETH_GAS: 306000,
    APPROVE_GAS: 30000,
    DEPOSIT_GAS: 21000,
    CCTP_THRESHOLD: 10000,
    DB_STATUS_IN_PROGRESS: 0,
    DB_STATUS_COMPLETED: 1,
    INITIAL_SLIPPAGE: 0.5,
    USD_DISPLAY_DIGIT: 2,
    TOKEN_DISPLAY_DIGIT: 7,
    TOKEN_DISPLAY_DIGIT_MOBILE: 3,
};
export const GTAG_IDS = {
    MAINNET: {
        COMMON: 'G-2B5LL831LD',
        SWAP: 'G-S016XC66XB',
    },
    TESTNET: {
        COMMON: 'G-VVHXYXRT77',
    },
};
export const currencies = [
    {
        img: eth,
        label: 'ETH',
        description: '',
        width: 15,
        tokenTypeIndex: Token.ETH,
    },
    {
        img: usdc,
        label: 'USDC',
        description: 'CCTP',
        width: 15,
        tokenTypeIndex: Token.USDC,
    },
];
export const loadingImages = ['twins.gif', 'family.gif', 'blink.gif'];
export const loadingDarkModeImages = [
    'twins-dark-mode.gif',
    'family-dark-mode.gif',
    'blink-dark-mode.gif',
];
export const WALLETS = [
    {
        id: 'MetaMask',
        name: 'MetaMask',
        img: 'metamask.svg',
        imgTransparent: 'metamask-transparent.svg',
    },
    {
        id: 'coinbaseWalletSDK',
        name: 'Coinbase Wallet',
        img: 'coinbase.svg',
        imgTransparent: 'coinbase-transparent.svg',
    },
    {
        id: 'Rabby',
        name: 'Rabby Wallet',
        img: 'rabby.svg',
        imgTransparent: 'rabby-transparent.svg',
    },
    {
        id: 'OKX',
        name: 'OKX Wallet',
        img: 'okx-wallet.svg',
        imgTransparent: 'okx-wallet-transparent.svg',
    },
    {
        id: 'Bitget Wallet',
        name: 'Bitget Wallet',
        img: 'bitget-wallet.svg',
        imgTransparent: 'bitget-wallet-transparent.svg',
    },
    {
        id: 'walletConnect',
        name: 'WalletConnect',
        img: 'wallet-connect.svg',
        imgTransparent: 'wallet-connect-transparent.svg',
    },
    {
        id: 'Taho Wallet',
        name: 'Taho Wallet',
        img: 'taho-wallet.svg',
        imgTransparent: 'taho-wallet-transparent.svg',
    },
    { id: 'Rainbow Wallet', img: 'rainbow-wallet.svg' },
    { id: 'Trust Wallet', img: 'trust-wallet.svg' },
    { id: 'Zerion Wallet', img: 'zerion-wallet.svg' },
    { id: 'Token Pocket', img: 'token-pocket-wallet.svg' },
    { id: 'Coin98 Wallet', img: 'coin98-wallet.svg' },
];
export const WALLET_CONNECT_WALLETS = [
// { id: 'FoxWallet', name: 'FoxWallet', img: 'fox-wallet.svg' },
// { id: 'imToken Wallet', name: 'imToken Wallet', img: 'imtoken-wallet.svg' },
// { id: 'Omni Wallet', name: 'Omni Wallet', img: 'omni-wallet.svg' },
// { id: 'Kraken Wallet', name: 'Kraken Wallet', img: 'kraken-wallet.png' },
];
export const MAIN_NETWORKS = [
    { label: 'Select Network', id: 0, isL2: false },
    {
        label: 'Ethereum',
        id: 1,
        img: 'ethereum.svg',
        historyImg: 'ethereum-history.svg',
        isL2: false,
        networkID: 1,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'OP Mainnet',
        id: 2,
        img: 'op.svg',
        historyImg: 'op-history.svg',
        isL2: true,
        networkID: 10,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Polygon',
        id: 3,
        img: 'polygon.svg',
        historyImg: 'polygon-history.svg',
        isL2: true,
        networkID: 137,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Arbitrum',
        id: 4,
        img: 'arbitrum.svg',
        historyImg: 'arbitrum-history.svg',
        isL2: true,
        networkID: 42161,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Scroll',
        id: 5,
        img: 'scroll.svg',
        historyImg: 'scroll-history.svg',
        isL2: true,
        networkID: 534352,
    },
    {
        label: 'ZkSync Era',
        id: 6,
        img: 'zksync.svg',
        historyImg: 'zksync-history.svg',
        isL2: true,
        networkID: 324,
    },
    {
        label: 'Base',
        id: 7,
        img: 'base.svg',
        historyImg: 'base-history.svg',
        isL2: true,
        networkID: 8453,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Polygon zkEvm',
        id: 8,
        img: 'polygon.svg',
        historyImg: 'polygon-history.svg',
        isL2: true,
        networkID: 1101,
    },
    {
        label: 'Linea',
        id: 9,
        img: 'linea.svg',
        historyImg: 'linea-history.svg',
        isL2: true,
        networkID: 59144,
    },
    {
        label: 'Taiko',
        id: 10,
        img: 'taiko.svg',
        historyImg: 'taiko-history.svg',
        isL2: true,
        networkID: 167000,
    },
    // {
    //   label: 'Mantle',
    //   id: 12,
    //   img: 'mantle_logo.svg',
    //   width: 1.5,
    //   height: 1.625,
    //   isL2: true,
    //   networkID: 5000,
    // },
    {
        label: 'Mode',
        id: 13,
        img: 'mode.svg',
        historyImg: 'mode-history.svg',
        isL2: true,
        networkID: 34443,
    },
    {
        label: 'X Layer',
        id: 14,
        img: 'xlayer.svg',
        historyImg: 'xlayer-history.svg',
        isL2: true,
        networkID: 196,
    },
    {
        label: 'Morph',
        id: 16,
        img: 'morph.svg',
        historyImg: 'morph-history.svg',
        isL2: true,
        networkID: 2818,
    },
];
export const TEST_NETWORKS = [
    { label: 'Select Network', id: 0, isL2: false },
    {
        label: 'Optimism(S)',
        id: 2,
        img: 'op.svg',
        historyImg: 'op-history.svg',
        isL2: true,
        networkID: 11155420,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Amoy',
        id: 3,
        img: 'polygon.svg',
        historyImg: 'polygon-history.svg',
        isL2: true,
        networkID: 80002,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Arbitrum(S)',
        id: 4,
        img: 'arbitrum.svg',
        historyImg: 'arbitrum-history.svg',
        isL2: true,
        networkID: 421614,
        isUsdc: true,
        isCctp: true,
    },
    {
        label: 'Scroll(T)',
        id: 5,
        img: 'scroll.svg',
        historyImg: 'scroll-history.svg',
        isL2: true,
        networkID: 534351,
    },
    {
        label: 'ZkSync Era(T)',
        id: 6,
        img: 'zksync.svg',
        historyImg: 'zksync-history.svg',
        isL2: true,
        networkID: 300,
        isUsdc: false,
    },
    {
        label: 'Base(S)',
        id: 7,
        img: 'base.svg',
        historyImg: 'base-history.svg',
        isL2: true,
        networkID: 84532,
        isUsdc: true,
        isCctp: true,
    },
    /*{
      label: 'Cardona',
      id: 8,
      img: 'polygon.svg',
      isL2: true,
      networkID: 2442,
    },*/
    {
        label: 'Linea(S)',
        id: 9,
        img: 'linea.svg',
        historyImg: 'linea-history.svg',
        isL2: true,
        networkID: 59141,
    },
    {
        label: 'Taiko(T)',
        id: 10,
        img: 'taiko.svg',
        historyImg: 'taiko-history.svg',
        isL2: true,
        networkID: 167009,
    },
    {
        label: 'Sepolia',
        id: 11,
        img: 'ethereum.svg',
        historyImg: 'ethereum-history.svg',
        isL2: false,
        networkID: 11155111,
        isUsdc: true,
        isCctp: true,
    },
    /*{
      label: 'Mantle(T)',
      id: 12,
      img: 'mantle.png',
      isL2: true,
      networkID: 5003,
    },*/
    {
        label: 'Mode(T)',
        id: 13,
        img: 'mode.svg',
        historyImg: 'mode-history.svg',
        isL2: true,
        networkID: 919,
    },
    {
        label: 'X Layer(T)',
        id: 14,
        img: 'xlayer.svg',
        historyImg: 'xlayer-history.svg',
        isL2: true,
        networkID: 195,
    },
    {
        label: 'Holesky',
        id: 15,
        img: 'ethereum.svg',
        historyImg: 'ethereum-history.svg',
        isL2: false,
        networkID: 17000,
    },
    {
        label: 'Morph(H)',
        id: 16,
        img: 'morph.svg',
        historyImg: 'morph-history.svg',
        isL2: true,
        networkID: 2810,
    },
    {
        label: 'Zircuit(T)',
        id: 17,
        img: 'zircuit.svg',
        historyImg: 'zircuit-history.svg',
        isL2: true,
        networkID: 48899,
    },
    {
        label: 'Fhenix(T)',
        id: 18,
        img: 'fhenix.svg',
        historyImg: 'fhenix-history.svg',
        isL2: true,
        networkID: 8008148,
    },
    {
        label: 'Unichain(T)',
        id: 19,
        img: 'unichain.svg',
        historyImg: 'unichain-history.svg',
        isL2: true,
        networkID: 1301,
    },
    {
        label: 'Minato',
        id: 20,
        img: 'minato.svg',
        historyImg: 'sonieum-history.svg',
        isL2: true,
        networkID: 1946,
    },
];
export const fhenixTestnet = {
    id: 8008148,
    name: 'Fhenix Testnet',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://api.nitrogen.fhenix.zone'],
        },
        public: {
            http: ['https://api.nitrogen.fhenix.zone'],
        },
    },
    blockExplorers: {
        default: {
            name: 'blockscout',
            url: 'https://explorer.nitrogen.fhenix.zone/',
        },
    },
};
export const NETWORKS = process.env.REACT_APP_IS_MAINNET === 'true' ? MAIN_NETWORKS : TEST_NETWORKS;
export const TOP_NETWORK_ID = process.env.REACT_APP_IS_MAINNET === 'true' ? 1 : 11155111;
const blockExplorers = {};
for (const network of currentNetworkList) {
    blockExplorers[network.chainId] = network.blockExplorerUrls;
}
export const delayedExecutionNetworks = [];
export const unSupportNetworks = [1101, 137, 34443, 196];
export { blockExplorers };
