import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { createContext, useContext, useState } from 'react';
const CompletedContext = createContext({
    selectedFromChain: null,
    setSelectedFromChain: () => { },
    selectedFromToken: null,
    setSelectedFromToken: () => { },
    selectedToChain: null,
    setSelectedToChain: () => { },
    selectedToToken: null,
    setSelectedToToken: () => { },
    fromAmount: '',
    setFromAmount: () => { },
    toAmount: '',
    setToAmount: () => { },
    fromTxLink: '',
    setFromTxLink: () => { },
    toTxLink: '',
    setToTxLink: () => { },
    recipient: '',
    setRecipient: () => { },
    totalFeeUSD: '',
    setTotalFeeUSD: () => { },
    executionDuration: null,
    setExecutionDuration: () => { },
    completedStatus: null,
    setCompletedStatus: () => { },
    title: '',
    setTitle: () => { },
});
export const SwapCompletedProvider = ({ children }) => {
    const [selectedFromChain, setSelectedFromChain] = useState(null);
    const [selectedFromToken, setSelectedFromToken] = useState(null);
    const [selectedToChain, setSelectedToChain] = useState(null);
    const [selectedToToken, setSelectedToToken] = useState(null);
    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState('');
    const [fromTxLink, setFromTxLink] = useState('');
    const [toTxLink, setToTxLink] = useState('');
    const [recipient, setRecipient] = useState('');
    const [totalFeeUSD, setTotalFeeUSD] = useState('0');
    const [executionDuration, setExecutionDuration] = useState(0);
    const [completedStatus, setCompletedStatus] = useState(null);
    const [title, setTitle] = useState('');
    const completedContextValue = {
        selectedFromChain,
        setSelectedFromChain,
        selectedFromToken,
        setSelectedFromToken,
        selectedToChain,
        setSelectedToChain,
        selectedToToken,
        setSelectedToToken,
        fromAmount,
        setFromAmount,
        toAmount,
        setToAmount,
        fromTxLink,
        setFromTxLink,
        toTxLink,
        setToTxLink,
        recipient,
        setRecipient,
        totalFeeUSD,
        setTotalFeeUSD,
        executionDuration,
        setExecutionDuration,
        completedStatus,
        setCompletedStatus,
        title,
        setTitle,
    };
    return (_jsx(_Fragment, { children: _jsx(CompletedContext.Provider, { value: completedContextValue, children: children }) }));
};
export const useSwapCompletedContext = () => {
    return useContext(CompletedContext);
};
