import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Token, Util } from '@pheasant-network/pheasant-sdk';
import check from '@/assets/new-icon/check.svg';
import checkDark from '@/assets/new-icon/check-dark.svg';
import complete from '@/assets/new-icon/complete.svg';
import arrow from '@/assets/new-icon/external-link-arrow.svg';
import arrowDark from '@/assets/new-icon/external-link-arrow-dark.svg';
import googlingGif from '@/assets/new-icon/gif/googling_blinking.gif';
import { useCurrentTxInfo, useIsDarkModeContext } from '@/context';
import { getExplorerUrl, getImage, getSelectableNetwork, shortenAddress, } from '@/utils';
import { useNetworkManagerContext } from '@/context/NetworkManagerContext';
const CompleteModalContent = () => {
    const { isDarkMode } = useIsDarkModeContext();
    const { currentTxInfo } = useCurrentTxInfo();
    // const currentTxInfo = {
    //   address: '0xFF814813d48D79a6A81e55EF2f96426bA1E37af5',
    //   recipient: '0x',
    //   status: 0,
    //   timestamp: Date.now(),
    //   txhash: '0x',
    //   destCode: 0,
    //   acceptTxHash: '0x',
    //   acceptedTxHash: '0x',
    //   newTradeTxHash: undefined,
    //   burnTxHash: undefined,
    //   mintTxHash: undefined,
    //   amount: '500000000000000000',
    //   fee: '50000000000000',
    //   sourceNetworkId: 11155111,
    //   destinationNetworkId: 534351,
    //   chainName: '',
    //   tokenTypeIndex: Token.ETH,
    //   estimateTime: { minutes: 1, seconds: 0 },
    //   tradeTime: { minutes: 1, seconds: 0 },
    //   tradeType: 0,
    // }
    const networkManager = useNetworkManagerContext();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mobile:hidden relative w-full flex flex-col justify-between items-center rounded-12 mt-8 p-6 pt-10 bg-secondary-10 dark:bg-neutral-900", children: [_jsx("div", { className: "absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2", children: _jsx("img", { src: googlingGif, alt: "googling", style: { width: '55px', height: '28px' } }) }), _jsxs("div", { className: "w-full flex justify-between items-center", children: [_jsxs("div", { className: "flex flex-col justify-center items-center w-[134px]", children: [_jsx("div", { className: "w-full text-label_l text-neutral-400 dark:text-neutral-200 mb-2", children: "From" }), _jsx("div", { className: "flex flex-col mb-2", children: _jsxs("div", { className: "relative border border-primary-500 rounded-100 p-2", children: [_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                                        width: `1.188rem`,
                                                        height: `1.188rem`,
                                                    } }), _jsx("img", { className: "inline", src: getImage('network/' +
                                                        getSelectableNetwork(currentTxInfo.sourceNetworkId).img), alt: getSelectableNetwork(currentTxInfo.sourceNetworkId).label, style: {
                                                        width: `48px`,
                                                        height: `48px`,
                                                    } })] }) }), _jsxs("a", { href: getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo.acceptedTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo.newTradeTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo.burnTxHash) ??
                                            '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 mb-1", children: [getSelectableNetwork(currentTxInfo.sourceNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }), _jsxs("div", { className: "text-label_l dark:text-neutral-0", children: [currentTxInfo.amount
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(currentTxInfo.amount)).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(currentTxInfo.amount)).toFixed(2))
                                                : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                ? 'USDC'
                                                : networkManager
                                                    .getNonNativeNetworkChainIds()
                                                    .includes(currentTxInfo.sourceNetworkId)
                                                    ? 'WETH'
                                                    : 'ETH'] })] }), _jsx("div", { className: "flex justify-center items-center", children: _jsx("img", { src: complete, alt: "complete", className: "absolute left-1/2 transform -translate-x-1/2", style: {
                                        width: '5.625rem',
                                        height: '5.625rem',
                                    } }) }), _jsxs("div", { className: "flex flex-col justify-center items-center w-[134px]", children: [_jsx("div", { className: "w-full text-label_l text-neutral-400 dark:text-neutral-200 mb-2", children: "To" }), _jsx("div", { className: "flex flex-col mb-2", children: _jsxs("div", { className: "relative border border-primary-500 rounded-100 p-2", children: [_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                                        width: `1.188rem`,
                                                        height: `1.188rem`,
                                                    } }), _jsx("img", { className: "inline", src: getImage('network/' +
                                                        getSelectableNetwork(currentTxInfo.destinationNetworkId)
                                                            .img), alt: getSelectableNetwork(currentTxInfo.destinationNetworkId)
                                                        .label, style: {
                                                        width: `48px`,
                                                        height: `48px`,
                                                    } })] }) }), _jsxs("a", { href: getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo.acceptTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo.txhash) ??
                                            getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo.mintTxHash) ??
                                            '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 mb-1", children: [getSelectableNetwork(currentTxInfo.destinationNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }), _jsxs("div", { className: "text-label_l dark:text-neutral-0", children: [currentTxInfo.amount && currentTxInfo.fee
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(2))
                                                : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                ? 'USDC'
                                                : networkManager
                                                    .getNonNativeNetworkChainIds()
                                                    .includes(currentTxInfo.destinationNetworkId)
                                                    ? 'WETH'
                                                    : 'ETH'] })] })] }), _jsxs("div", { className: "mt-8 self-start", children: [_jsx("div", { className: "flex justify-col items-center mb-1", children: _jsxs("div", { className: "flex justify-start items-center", children: [_jsx("div", { className: "text-label_m text-neutral-400 dark:text-neutral-200", children: "Fee:\u00A0" }), _jsxs("div", { className: "text-label_m dark:text-neutral-0", children: [currentTxInfo.fee
                                                    ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                        ? parseFloat(Number(Util.fromWei(currentTxInfo.fee)).toFixed(7))
                                                        : parseFloat(Number(Util.fromUSDC(currentTxInfo.fee)).toFixed(2))
                                                    : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                    ? 'USDC'
                                                    : networkManager
                                                        .getNonNativeNetworkChainIds()
                                                        .includes(currentTxInfo.destinationNetworkId)
                                                        ? 'WETH'
                                                        : 'ETH'] })] }) }), _jsxs("div", { className: "flex justify-start items-center mb-1", children: [_jsx("div", { className: "text-label_m text-neutral-400 dark:text-neutral-200", children: "Time:\u00A0" }), _jsxs("div", { className: "text-label_m dark:text-neutral-0", children: [String(currentTxInfo.tradeTime.minutes).padStart(2, '0'), " min", ' ', String(currentTxInfo.tradeTime.seconds).padStart(2, '0'), " sec"] })] }), _jsxs("div", { className: "flex justify-start items-center", children: [_jsx("div", { className: "text-label_m text-neutral-400 dark:text-neutral-200", children: "Receiving address:\u00A0" }), _jsx("div", { className: "text-label_m dark:text-neutral-0", children: shortenAddress(currentTxInfo.recipient, 7) })] })] })] }), _jsxs("div", { className: "hidden relative w-full mobile:flex flex-col justify-between items-center rounded-12 mt-6 p-6 pt-10 bg-secondary-10 dark:bg-neutral-900", children: [_jsx("div", { className: "flex justify-center items-center mb-8", children: _jsx("img", { src: complete, alt: "complete", style: {
                                width: '5.625rem',
                                height: '5.625rem',
                            } }) }), _jsx("div", { className: "absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2", children: _jsx("img", { src: googlingGif, alt: "googling", style: { width: '55px', height: '28px' } }) }), _jsxs("div", { className: "w-full flex justify-between items-center", children: [_jsxs("div", { className: "flex flex-col justify-center items-center w-[124px]", children: [_jsx("div", { className: "flex flex-col mb-2", children: _jsxs("div", { className: "relative border border-primary-500 rounded-[30px] p-2", children: [_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                                        width: `20px`,
                                                        height: `20px`,
                                                    } }), _jsx("img", { className: "inline", src: getImage('network/' +
                                                        getSelectableNetwork(currentTxInfo.sourceNetworkId).img), alt: getSelectableNetwork(currentTxInfo.sourceNetworkId).label, style: {
                                                        width: `2.5rem`,
                                                        height: `2.5rem`,
                                                    } })] }) }), _jsxs("a", { href: getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo.acceptedTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo.newTradeTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo.burnTxHash) ??
                                            '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 mb-1", children: [getSelectableNetwork(currentTxInfo.sourceNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline ml-1", width: "12px", height: "12px" })] }), _jsxs("div", { className: "text-label_l font-medium dark:text-neutral-0", children: [currentTxInfo.amount
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(currentTxInfo.amount)).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(currentTxInfo.amount)).toFixed(2))
                                                : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                ? 'USDC'
                                                : networkManager
                                                    .getNonNativeNetworkChainIds()
                                                    .includes(currentTxInfo.sourceNetworkId)
                                                    ? 'WETH'
                                                    : 'ETH'] })] }), _jsxs("div", { className: "flex flex-col justify-center items-center w-[124px]", children: [_jsx("div", { className: "flex flex-col mb-2", children: _jsxs("div", { className: "relative border border-primary-500 rounded-[30px] p-2", children: [_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                                        width: `20px`,
                                                        height: `20px`,
                                                    } }), _jsx("img", { className: "inline", src: getImage('network/' +
                                                        getSelectableNetwork(currentTxInfo.destinationNetworkId)
                                                            .img), alt: getSelectableNetwork(currentTxInfo.destinationNetworkId)
                                                        .label, style: {
                                                        width: `2.5rem`,
                                                        height: `2.5rem`,
                                                    } })] }) }), _jsxs("a", { href: getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo.acceptTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo.txhash) ??
                                            getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo.mintTxHash) ??
                                            '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 mb-1", children: [getSelectableNetwork(currentTxInfo.destinationNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline ml-1", width: "12px", height: "12px" })] }), _jsxs("div", { className: "text-label_l font-medium dark:text-neutral-0", children: [currentTxInfo.amount && currentTxInfo.fee
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(2))
                                                : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                ? 'USDC'
                                                : networkManager
                                                    .getNonNativeNetworkChainIds()
                                                    .includes(currentTxInfo.destinationNetworkId)
                                                    ? 'WETH'
                                                    : 'ETH'] })] })] }), _jsxs("div", { className: "mt-8 self-start", children: [_jsx("div", { className: "flex justify-col items-center", children: _jsxs("div", { className: "flex justify-start items-center mb-1", children: [_jsx("div", { className: "text-label_m text-neutral-400 dark:text-neutral-200", children: "Fee:\u00A0" }), _jsxs("div", { className: "text-label_m dark:text-neutral-0", children: [currentTxInfo.fee
                                                    ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                        ? parseFloat(Number(Util.fromWei(currentTxInfo.fee)).toFixed(7))
                                                        : parseFloat(Number(Util.fromUSDC(currentTxInfo.fee)).toFixed(2))
                                                    : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                    ? 'USDC'
                                                    : networkManager
                                                        .getNonNativeNetworkChainIds()
                                                        .includes(currentTxInfo.destinationNetworkId)
                                                        ? 'WETH'
                                                        : 'ETH'] })] }) }), _jsxs("div", { className: "flex justify-start items-center mb-1", children: [_jsx("div", { className: "text-label_m text-neutral-400 dark:text-neutral-200", children: "Time:\u00A0" }), _jsxs("div", { className: "text-label_m dark:text-neutral-0", children: [String(currentTxInfo.tradeTime.minutes).padStart(2, '0'), " min", ' ', String(currentTxInfo.tradeTime.seconds).padStart(2, '0'), " sec"] })] }), _jsxs("div", { className: "flex justify-start items-center", children: [_jsx("div", { className: "text-label_m text-neutral-400 dark:text-neutral-200", children: "Receiving address:\u00A0" }), _jsx("div", { className: "text-label_m dark:text-neutral-0", children: shortenAddress(currentTxInfo.recipient, 7) })] })] })] })] }));
};
export default CompleteModalContent;
