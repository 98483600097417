// context
import { useSwapSelectedRouteContext } from '@/context/swap/SelectedRouteContext';
import { useSlippageContext } from '@/context/swap/SlippageContext';
// constants
import { constants } from '@/constants';
export const useResetInput = () => {
    const { setSelectedFromChain, setSelectedFromToken, setSelectedToChain, setSelectedToToken, setFromAmount, setFromAmountUSD, setToAmount, setToAmountUSD, setRecipient, setTotalFeeUSD, setSwapFeeUSD, setRelayerFee, setRelayerFeeUSD, setProtocolFee, setProtocolFeeUSD, setEstimatedTime, setCanSwap, setIsToRoute, setIsFetchingRoute, setRouteError, setPrepareRouteError, setRoute, setNeedApprove, setApprovedTxLink, setTransactionHash, setProcessingStatus, } = useSwapSelectedRouteContext();
    const { setSlippage } = useSlippageContext();
    const resetFromInput = () => {
        setFromAmount('');
        setFromAmountUSD('0');
    };
    const resetToInput = () => {
        setToAmount('');
        setToAmountUSD('0');
        setIsToRoute(false);
    };
    const resetFees = () => {
        setTotalFeeUSD('0');
        setSwapFeeUSD('0');
        setRelayerFee('0');
        setRelayerFeeUSD('0');
        setProtocolFee('0');
        setProtocolFeeUSD('0');
    };
    const resetOthers = () => {
        resetFees();
        setEstimatedTime('0');
    };
    const resetSwap = () => {
        setRouteError(false);
        setPrepareRouteError(false);
        setCanSwap(false);
        setIsFetchingRoute(false);
        setRoute(null);
        setNeedApprove(false);
        setApprovedTxLink('');
        setTransactionHash('');
        setProcessingStatus(null);
    };
    const resetInput = () => {
        resetFromInput();
        resetToInput();
        resetOthers();
        resetSwap();
    };
    const resetNetwork = () => {
        setSelectedFromChain(null);
        setSelectedFromToken(null);
        setSelectedToChain(null);
        setSelectedToToken(null);
    };
    const resetAll = (address) => {
        setRecipient(address);
        resetInput();
        resetNetwork();
        setSlippage(constants.INITIAL_SLIPPAGE);
    };
    return {
        resetFromInput,
        resetToInput,
        resetFees,
        resetOthers,
        resetInput,
        resetSwap,
        resetAll,
    };
};
