// wagmi
import { useAccount } from 'wagmi';
import { switchChain } from '@wagmi/core';
import { wagmiConfig } from '@/wagmiConfig';
// context
import { useModalContext } from '@/context';
import { useSwapSelectedRouteContext } from '@/context/swap/SelectedRouteContext';
import { useSwapCompletedContext } from '@/context/swap/CompletedContext';
import { useCurrentSwapTxInfo } from '@/context/swap/CurrentSwapTxInfoContext';
// components
import { SwapProcessingStatus } from '@/components/swap/ProcessingModalContent';
// domain
import { SwapStatusTypes } from '@/domain/history/HistoryContent';
// hooks
import { useResetInput } from '@/hooks/swap/useReset';
// sdk
import { WalletOrProvider, isNativeToken, getEthOrTokenAddress, postRequest, Util, } from '@pheasant-network/pheasant-sdk';
// utils
import { getEthersSigner } from '@/utils/ethers';
// lib
import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { ethers } from 'ethers';
import { generateUUID } from '@/utils';
export const useSwapTrade = (pheasantSwap, networkManager) => {
    /********** Context **********/
    const { selectedFromChain, selectedFromToken, selectedToChain, selectedToToken, fromAmount, toAmount, needApprove, canSwap, recipient, totalFeeUSD, route, setProcessingStatus, approvedTxLink, setApprovedTxLink, setTransactionHash, } = useSwapSelectedRouteContext();
    const { setSelectedFromChain, setSelectedFromToken, setSelectedToChain, setSelectedToToken, setFromAmount, setToAmount, setFromTxLink, setToTxLink, setRecipient, setTotalFeeUSD, setExecutionDuration, setCompletedStatus, setTitle, } = useSwapCompletedContext();
    const { setIsProcessingModalOpen, setIsCompleteModalOpen } = useModalContext();
    const { addTx, updateTxInfo, removeProcessing } = useCurrentSwapTxInfo();
    /********** Hooks **********/
    const { address, connector } = useAccount();
    const { resetAll } = useResetInput();
    /********** Functions **********/
    const isNative = (token) => {
        return token.isNative ? token.isNative : false;
    };
    const switchConnectedChain = async (chainId) => {
        // FIXME: Currently only supports EVM
        // For Wallet
        await connector.switchChain({
            addEthereumChainParameter: {},
            chainId: Number(chainId),
        });
        // For SDK
        const chain = await switchChain(wagmiConfig, { chainId: Number(chainId) });
        const walletOrProvider = WalletOrProvider.init(await getEthersSigner(chain));
        pheasantSwap.current.walletOrProvider = walletOrProvider;
    };
    const getNetworkData = async (chainId, exclude) => {
        const networks = await pheasantSwap.current.getNetworkData(chainId, exclude);
        return networks
            .map((network) => {
            const nativeToken = network.tokens.find((token) => token.isNative ? true : false);
            network.tokens = network.tokens.sort((a, b) => {
                if (a.symbol.toLowerCase() < b.symbol.toLowerCase())
                    return -1;
                if (a.symbol.toLowerCase() > b.symbol.toLowerCase())
                    return 1;
                return 0;
            });
            if (nativeToken) {
                network.tokens = [nativeToken].concat(...network.tokens.filter((token) => token.isNative ? false : true));
            }
            return network;
        })
            .sort((a, b) => {
            if (a.chain.chainName.toLowerCase() < b.chain.chainName.toLowerCase())
                return -1;
            if (a.chain.chainName.toLowerCase() > b.chain.chainName.toLowerCase())
                return 1;
            return 0;
        });
    };
    const makeSignature = (url, query, body, requestId) => {
        const message = sha256(JSON.stringify({ url, ...query, ...body, requestId }));
        const signature = Base64.stringify(hmacSHA512(message, process.env.REACT_APP_SWAP_PROXY_GUARD_KEY || ''));
        return signature;
    };
    const getRoute = async (request, controller) => {
        const abortController = controller || new AbortController();
        const signal = abortController.signal;
        const requestId = generateUUID();
        try {
            const res = await axios.post(`${process.env.REACT_APP_SWAP_PROXY_ENDPOINT}/sdk/route`, request, {
                signal,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Request-ID': requestId,
                    'X-Signature': makeSignature('/route', {}, request, requestId),
                },
            });
            if (res.status === 200 && Number(res.data.status) === 200) {
                return { route: res.data.data, isCancel: false };
            }
        }
        catch (err) {
            console.log(err.message);
            if (err.message === 'canceled') {
                return { route: null, isCancel: true };
            }
        }
        return { route: null, isCancel: false };
    };
    const getRelayerFee = async (chain, token, amount, isFrom) => {
        try {
            return await pheasantSwap.current.getRelayerFee({
                contracts: {
                    PheasantNetworkSwapProxy: networkManager.current.getLatestSwapContractAddress(chain.chainNameLower),
                    PheasantNetworkSwapParameters: networkManager.current.getLatestSwapParametersContractAddress(chain.chainNameLower),
                },
                chain,
                token,
            }, amount, isFrom);
        }
        catch (error) {
            console.log(error);
            return null;
        }
    };
    const getTrades = async (address) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/swap/getSwapTrades?userAddress=${address}`);
            return res.data.data.swapTrades;
        }
        catch (error) {
            console.log(error);
        }
    };
    const hasEnoughAllowance = async (route, fromAmount) => {
        if (selectedFromChain.chainId !== route.fromChainId) {
            console.warn(`The route has different chain id (${route.fromChainId}) from selected from chain (${selectedFromChain.chainId})`);
            return;
        }
        if (isNativeToken(route.fromToken.address)) {
            return true; // no need to approve
        }
        await switchConnectedChain(route.fromChainId);
        const contract = networkManager.current.getLatestSwapContractAddress(selectedFromChain.chainNameLower);
        const allowance = await pheasantSwap.current.getTokenAllowance(contract, selectedFromToken.address);
        return Util.toBN(fromAmount).lte(allowance);
    };
    const approve = async (route, fromAmount) => {
        if (!canSwap)
            return;
        if (selectedFromChain.chainId !== route.fromChainId) {
            console.warn(`The route has different chain id (${route.fromChainId}) from selected from chain (${selectedFromChain.chainId})`);
            return;
        }
        await switchConnectedChain(route.fromChainId);
        setIsProcessingModalOpen(true);
        setProcessingStatus(SwapProcessingStatus.APPROVING);
        if (!(await hasEnoughAllowance(route, fromAmount))) {
            const contract = networkManager.current.getLatestSwapContractAddress(selectedFromChain.chainNameLower);
            let receipt;
            if (process.env.REACT_APP_ENV === 'local') {
                await new Promise((f) => setTimeout(f, 3000));
                receipt = {
                    transactionHash: ethers.utils.keccak256(Buffer.from(Util.toBN(Math.floor(Math.random() * 10000))
                        .mul(Util.toBN(new Date().getTime().toString()))
                        .toString())),
                };
            }
            else {
                receipt = await pheasantSwap.current.approveIfNeed(contract, selectedFromToken.address, fromAmount);
            }
            if (!approvedTxLink &&
                selectedFromChain.blockExplorerUrls &&
                selectedFromChain.blockExplorerUrls.length > 0) {
                setApprovedTxLink(`${selectedFromChain.blockExplorerUrls[0]}/tx/${receipt.transactionHash}`);
            }
        }
        setProcessingStatus(SwapProcessingStatus.APPROVED);
    };
    const executeSwap = async (route) => {
        if (!canSwap)
            return;
        if (selectedFromChain.chainId !== route.fromChainId) {
            console.warn(`The route has different chain id (${route.fromChainId}) from selected from chain (${selectedFromChain.chainId})`);
            return;
        }
        await switchConnectedChain(route.fromChainId);
        if (!needApprove) {
            setIsProcessingModalOpen(true);
        }
        setProcessingStatus(SwapProcessingStatus.SWAP_EXECUTING);
        const contract = networkManager.current.getLatestSwapContractAddress(selectedFromChain.chainNameLower);
        const amount = Util.toBN(Util.parseUnits(fromAmount, selectedFromToken.decimals)).toString();
        let receipt;
        if (process.env.REACT_APP_ENV === 'local') {
            await new Promise((f) => setTimeout(f, 3000));
            receipt = {
                transactionHash: ethers.utils.keccak256(Buffer.from(Util.toBN(Math.floor(Math.random() * 10000))
                    .mul(Util.toBN(new Date().getTime().toString()))
                    .toString())),
            };
        }
        else {
            receipt = await pheasantSwap.current.execute(contract, {
                data: route.transactionRequest.data,
                toChainId: route.toChainId,
                swapToolIndex: route.tool,
                toolContract: route.transactionRequest.to,
                fromToken: getEthOrTokenAddress(route.fromToken.address),
                toToken: getEthOrTokenAddress(route.toToken.address),
                amount,
                value: isNativeToken(route.fromToken.address)
                    ? amount
                    : isNative(selectedFromToken)
                        ? '0'
                        : route.transactionRequest.value,
                gas: route.transactionRequest.gasLimit,
                quoteTimestamp: route.quoteTimestamp,
            });
        }
        addTx({
            fromTxhash: receipt.transactionHash,
            fromNetworkId: selectedFromChain.chainId,
            fromTokenAddress: getEthOrTokenAddress(route.fromToken.address),
            fromAmount: Util.parseUnits(fromAmount, selectedFromToken.decimals),
            fromExplorerUrl: `${selectedFromChain.blockExplorerUrls[0]}/tx/${receipt.transactionHash}`,
            toNetworkId: selectedToChain.chainId,
            toTokenAddress: getEthOrTokenAddress(route.toToken.address),
            toAmount: Util.parseUnits(toAmount, selectedToToken.decimals),
            status: SwapStatusTypes.Pending,
            route,
            fromChain: selectedFromChain,
            fromToken: selectedFromToken,
            toChain: selectedToChain,
            toToken: selectedToToken,
            countdownTime: route.executionDuration,
            processingStatus: SwapProcessingStatus.SWAP_PROCESSING,
            isProcessing: true,
            isTracking: false,
        });
        setProcessingStatus(SwapProcessingStatus.SWAP_PROCESSING);
        setTransactionHash(receipt.transactionHash);
        if (process.env.REACT_APP_ENV === 'local') {
            await axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/swap/sync?chainId=${route.fromChainId}&txHash=${receipt.transactionHash}&fromToken=${getEthOrTokenAddress(route.fromToken.address)}&fromAmount=${amount}&toChainId=${route.toChainId}&toToken=${getEthOrTokenAddress(route.toToken.address)}`)
                .catch((error) => {
                console.log(error);
            });
        }
        else {
            await pheasantSwap.current
                .waitForTransaction(receipt.transactionHash, 2)
                .then(async (res) => {
                if (res) {
                    await axios
                        .post(`${process.env.REACT_APP_API_ENDPOINT}/swap/sync?chainId=${route.fromChainId}&txHash=${receipt.transactionHash}`)
                        .catch((error) => {
                        console.log(error);
                    });
                }
            })
                .catch((error) => {
                console.log(error);
            });
        }
    };
    const copyToCompleted = (res) => {
        setSelectedFromChain(selectedFromChain);
        setSelectedFromToken(selectedFromToken);
        setSelectedToChain(selectedToChain);
        setSelectedToToken(selectedToToken);
        setFromAmount(fromAmount);
        setToAmount(res.toNetwork.amount
            ? Util.formatUnits(res.toNetwork.amount, selectedToToken.decimals)
            : '');
        setFromTxLink(res.fromNetwork.explorerUrl);
        setToTxLink(res.toNetwork.explorerUrl);
        setRecipient(recipient);
        setTotalFeeUSD(totalFeeUSD);
        if (res.completedTimestamp && res.fromNetwork.timestamp) {
            setExecutionDuration(res.completedTimestamp - res.fromNetwork.timestamp);
        }
        else {
            setExecutionDuration(route.executionDuration);
        }
        setCompletedStatus(res.status);
        if (res.status === SwapStatusTypes.Success) {
            setTitle('Completed!');
        }
        else {
            setTitle('Failed');
        }
    };
    const tracking = async (chainId, txHash, fromProcessing) => {
        await axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/swap/tracking?chainId=${chainId}&txHash=${txHash}`)
            .then(async (result) => {
            const swapTrade = result.data.data.swapTrade;
            if (!swapTrade) {
                // Make sure to sync first. Duplicate requests will be ignored by the relayer.
                await axios
                    .post(`${process.env.REACT_APP_API_ENDPOINT}/swap/sync?chainId=${chainId}&txHash=${txHash}`)
                    .catch((error) => {
                    console.log(error);
                });
            }
            if (swapTrade?.fromNetwork?.timestamp &&
                swapTrade?.completedTimestamp) {
                if (fromProcessing) {
                    copyToCompleted(swapTrade);
                    setIsProcessingModalOpen(false);
                    removeProcessing();
                    resetAll(address);
                    setIsCompleteModalOpen(true);
                }
                updateTxInfo(txHash, {
                    status: swapTrade.status,
                    toExplorerUrl: swapTrade.toNetwork.explorerUrl,
                    processingStatus: SwapProcessingStatus.NOTHING,
                    isProcessing: false,
                    isTracking: false,
                });
                return true;
            }
        })
            .catch((error) => {
            console.log(error);
        });
        return false;
    };
    const logTxError = async (route, err) => {
        const requestId = generateUUID();
        const request = {
            message: err.message || 'Unknown error',
            name: err.name || 'Error',
            timestamp: new Date().toISOString(),
            address,
            route,
        };
        try {
            await postRequest([
                `${process.env.REACT_APP_SWAP_PROXY_ENDPOINT}/functions/logTransactionError`,
            ], request, {
                'X-Request-ID': requestId,
                'X-Signature': makeSignature('/logTransactionError', {}, request, requestId),
            }, (res) => Number(res.data.status) === 200).then((result) => result.response.data);
        }
        catch (err) {
            console.log(err.message);
            return null;
        }
    };
    return {
        getNetworkData,
        getRoute,
        getRelayerFee,
        getTrades,
        hasEnoughAllowance,
        approve,
        executeSwap,
        tracking,
        logTxError,
    };
};
