import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SwapNetworksProvider } from './SwapNetworksContext';
import { SwapSelectedRouteProvider } from './SelectedRouteContext';
import { SwapCompletedProvider } from './CompletedContext';
import { SlippageProvider } from './SlippageContext';
import { CurrentSwapTxInfoProvider } from './CurrentSwapTxInfoContext';
const SwapProvider = ({ children }) => {
    return (_jsx(_Fragment, { children: _jsx(SwapNetworksProvider, { children: _jsx(SwapSelectedRouteProvider, { children: _jsx(CurrentSwapTxInfoProvider, { children: _jsx(SwapCompletedProvider, { children: _jsx(SlippageProvider, { children: children }) }) }) }) }) }));
};
export default SwapProvider;
