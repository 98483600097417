import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { memo, useState, useEffect, useRef } from 'react';
// context
import { useSwapSelectedRouteContext } from '@/context/swap/SelectedRouteContext';
import { useCurrentSwapTxInfo } from '@/context/swap/CurrentSwapTxInfoContext';
// components
import { SwapProcessingStatus } from '@/components/swap/ProcessingModalContent';
const CountdownTime = memo(({ isMobile, tracking }) => {
    /********** Context **********/
    const { route, selectedFromChain, processingStatus, transactionHash } = useSwapSelectedRouteContext();
    const { processingTx } = useCurrentSwapTxInfo();
    /********** State **********/
    const isTracking = useRef(false);
    const [countdownTime, setCountdownTime] = useState(processingTx ? processingTx.countdownTime : route.executionDuration);
    const [data, setData] = useState({
        processingStatus: processingStatus,
        txHash: transactionHash,
        chainId: selectedFromChain.chainId,
    });
    /********** Functions **********/
    const calculateInterval = (countdownTime) => {
        let intervalSec = 5;
        if (countdownTime > 0 && !Number.isNaN(countdownTime)) {
            intervalSec = Math.floor(countdownTime / 2);
            if (intervalSec > 60) {
                intervalSec = 60;
            }
        }
        return intervalSec * 1000;
    };
    const trackingProcess = async (chainId, txHash, countdownTime) => {
        try {
            isTracking.current = true;
            const isCompeleted = await tracking(chainId, txHash, true);
            if (!isCompeleted) {
                await new Promise((f) => setTimeout(f, calculateInterval(countdownTime)));
            }
        }
        finally {
            isTracking.current = false;
        }
    };
    /********** Effect **********/
    useEffect(() => {
        if (processingTx) {
            setData({
                processingStatus: processingTx.processingStatus,
                txHash: processingTx.fromTxhash,
                chainId: processingTx.fromChain.chainId,
            });
        }
    }, [processingTx]);
    useEffect(() => {
        if (processingStatus === SwapProcessingStatus.SWAP_PROCESSING) {
            const timerId = setInterval(() => {
                setCountdownTime((prevTime) => {
                    if (!isTracking.current) {
                        trackingProcess(data.chainId, data.txHash, prevTime - 1);
                    }
                    if (prevTime > 0) {
                        return prevTime - 1;
                    }
                });
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [processingStatus]);
    /********** Modal Parts **********/
    return (_jsx(_Fragment, { children: isMobile ? (countdownTime && countdownTime > 0 ? (_jsxs("div", { className: "font-dosis flex justify-center items-end", children: [_jsx("div", { className: "text-countdown_number font-light", children: String(Math.floor(countdownTime / 60)).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time font-sans", children: "min" }), _jsx("div", { className: "text-countdown_number font-light", children: String(countdownTime % 60).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time font-sans", children: "sec" })] })) : (_jsx("div", { className: "flex justify-center items-end text-countdown_time font-sans", children: "Almost there..." }))) : countdownTime && countdownTime > 0 ? (_jsxs("div", { className: "font-dosis flex justify-center items-end mt-6", children: [_jsx("div", { className: "text-countdown_number font-light", children: String(Math.floor(countdownTime / 60)).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time font-sans", children: "min" }), _jsx("div", { className: "text-countdown_number font-light", children: String(countdownTime % 60).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time font-sans", children: "sec" })] })) : (_jsx("div", { className: "flex justify-center items-end text-countdown_time font-sans mt-6", children: "Almost there..." })) }));
});
export default CountdownTime;
