import axios from 'axios';
import { useEffect } from 'react';
import { constants } from '@/constants';
import { PhaseType, useModalContext, usePhaseContext } from '@/context';
import { calculateTradeTime } from '@/utils';
import { TradeType, useCurrentTxInfo } from '@/context/CurrentTxInfoContext';
const usePollingStatusWithApi = () => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const { isProcessingModalOpen, setIsProcessingModalOpen, setIsCompleteModalOpen, } = useModalContext();
    const { latestTx, updateTxInfo } = useCurrentTxInfo();
    const { phase, setPhase } = usePhaseContext();
    useEffect(() => {
        if (!isProcessingModalOpen)
            return;
        if (latestTx.tradeType === undefined)
            return;
        if (phase !== PhaseType.DEPOSITED &&
            phase !== PhaseType.SENT &&
            phase !== PhaseType.DOWNWARD_ETH_SENT)
            return;
        const targetTx = latestTx; // fixed the tx using in this Interval
        const intervalId = setInterval(() => {
            if (targetTx.tradeType === TradeType.CCTP) {
                axios
                    .get(`${isMainnet
                    ? process.env.REACT_APP_API_ENDPOINT
                    : process.env.REACT_APP_TESTNET_API_ENDPOINT}/cctpTrades/newestTrade?userAddress=${targetTx.address}&txhash=${targetTx.burnTxHash}&chainId=${targetTx.sourceNetworkId}`)
                    .then((result) => {
                    console.log(result);
                    if (result.data &&
                        result.data.data &&
                        result.data.data.newestTrade &&
                        result.data.data.newestTrade.length > 0 &&
                        result.data.data.newestTrade[0].status ===
                            constants.DB_STATUS_COMPLETED) {
                        const tradeFromApi = result.data.data.newestTrade[0];
                        const tradeTime = calculateTradeTime(tradeFromApi.burnTimestamp, tradeFromApi.mintTimestamp, targetTx.estimateTime);
                        updateTxInfo(targetTx.id, {
                            status: constants.DB_STATUS_COMPLETED,
                            mintTxHash: tradeFromApi.mintTxHash,
                            tradeTime,
                        });
                        clearInterval(intervalId);
                        setIsProcessingModalOpen(false);
                        setIsCompleteModalOpen(true);
                        setPhase(PhaseType.NOTHING_STARTED);
                    }
                })
                    .catch((error) => {
                    console.log(error);
                });
            }
            else {
                const isUpward = targetTx.tradeType === TradeType.UPWARD ? true : false;
                const chainId = isUpward
                    ? targetTx.destinationNetworkId
                    : targetTx.sourceNetworkId;
                const txhash = isUpward
                    ? targetTx.acceptedTxHash
                    : targetTx.newTradeTxHash;
                axios
                    .get(`${isMainnet
                    ? process.env.REACT_APP_API_ENDPOINT
                    : process.env.REACT_APP_TESTNET_API_ENDPOINT}/userTrades?userAddress=${targetTx.address}&txhash=${txhash}&chainId=${chainId}&isUpward=${isUpward}`)
                    .then((result) => {
                    if (result.data && result.data.data && result.data.data.trade) {
                        const tradeFromApi = result.data.data.trade;
                        console.log(tradeFromApi);
                        if (isUpward) {
                            if (tradeFromApi && tradeFromApi.acceptTxHash) {
                                const tradeTime = calculateTradeTime(tradeFromApi.acceptedTxTimestamp, tradeFromApi.acceptTimestamp, targetTx.estimateTime);
                                updateTxInfo(targetTx.id, {
                                    status: constants.DB_STATUS_COMPLETED,
                                    acceptTxHash: tradeFromApi.acceptTxHash,
                                    tradeTime,
                                });
                                clearInterval(intervalId);
                                setIsProcessingModalOpen(false);
                                setIsCompleteModalOpen(true);
                                setPhase(PhaseType.NOTHING_STARTED);
                            }
                        }
                        else {
                            if (tradeFromApi && tradeFromApi.txhash) {
                                const tradeTime = calculateTradeTime(tradeFromApi.newTradeTimestamp, tradeFromApi.sendTimestamp, targetTx.estimateTime);
                                updateTxInfo(targetTx.id, {
                                    status: constants.DB_STATUS_COMPLETED,
                                    txhash: tradeFromApi.txhash,
                                    tradeTime,
                                });
                                clearInterval(intervalId);
                                setIsProcessingModalOpen(false);
                                setIsCompleteModalOpen(true);
                                setPhase(PhaseType.NOTHING_STARTED);
                            }
                        }
                    }
                })
                    .catch((error) => {
                    console.log(error);
                });
            }
        }, 5000);
    }, [
        phase === PhaseType.DEPOSITED,
        phase === PhaseType.SENT,
        phase === PhaseType.DOWNWARD_ETH_SENT,
    ]);
};
export default usePollingStatusWithApi;
