import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '@/components/modal.css';
import { memo, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import logo from '@/assets/new-icon/logo-light.svg';
import logoDark from '@/assets/new-icon/logo-dark.svg';
import open from '@/assets/new-icon/header/hamburger-open.svg';
import close from '@/assets/new-icon/header/hamburger-close.svg';
import openDark from '@/assets/new-icon/header/hamburger-open-dark.svg';
import closeDark from '@/assets/new-icon/header/hamburger-close-dark.svg';
import home from '@/assets/new-icon/header/home.svg';
import homeDark from '@/assets/new-icon/header/home-dark.svg';
import l2Learn from '@/assets/new-icon/header/l2-learn.svg';
import internalArrow from '@/assets/new-icon/header/internal-link-arrow.svg';
import internalArrowDark from '@/assets/new-icon/header/internal-link-arrow-dark.svg';
import arrow from '@/assets/new-icon/external-link-arrow.svg';
import arrowDark from '@/assets/new-icon/external-link-arrow-dark.svg';
import history from '@/assets/new-icon/header/history.svg';
import historyDark from '@/assets/new-icon/header/history-dark.svg';
import bridge from '@/assets/new-icon/history/bridge.svg';
import bridgeDark from '@/assets/new-icon/history/bridge-dark.svg';
import swap from '@/assets/new-icon/history/swap.svg';
import swapDark from '@/assets/new-icon/history/swap-dark.svg';
import { useIsDarkModeContext, useIsVisibleNewsBanner, } from '@/context';
import HeaderButtons from '@/domain/header/HeaderButtons';
import { shortenAddress } from '@/utils';
import WalletModal from '@/components/WalletModal';
import { useAccount } from 'wagmi';
import Loading from '@/components/Loading';
import { getImage } from '@/utils';
import DarkModeToggleButton from '@/domain/header/DarkModeToggleButton';
import NetworkToggleButton from '@/domain/header/NetworkToggleButton';
import AccountContent from '@/domain/header/AccountContent';
import SnsNav from '@/domain/header/SnsNav';
import { WALLETS } from '@/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCurrentTxInfo } from '@/context/CurrentTxInfoContext';
import { useCurrentSwapTxInfo } from '@/context/swap/CurrentSwapTxInfoContext';
const Header = memo(() => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const location = useLocation();
    const navigate = useNavigate();
    const { isDarkMode } = useIsDarkModeContext();
    const { isVisibleNewsBanner } = useIsVisibleNewsBanner();
    const { hasProgressingTx } = useCurrentTxInfo();
    const { hasProgressingTx: hasProgressingSwapTx } = useCurrentSwapTxInfo();
    // TODO for walletconnect
    //const { walletImagePath } = useWalletImagePath()
    const { address, connector, isConnected } = useAccount();
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const [isOpenHamburgerMenu, setIsOpenHamburgerMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const button = document.getElementById('mobile-hamburger-button');
        if (button) {
            const buttonRect = button.getBoundingClientRect();
            const heightToBottom = window.innerHeight - 3 * 14 - buttonRect.bottom;
            setHeight(heightToBottom);
        }
    }, []);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setIsWalletModalOpen(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const [menuRightPosition, setMenuRightPosition] = useState(0);
    const menuButtonRef = useRef(null);
    useEffect(() => {
        if (menuButtonRef.current) {
            const buttonRect = menuButtonRef.current.getBoundingClientRect();
            setMenuRightPosition(window.innerWidth - buttonRect.right);
        }
    }, [isOpenHamburgerMenu]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mobile-header hidden", children: [_jsxs("header", { className: "justify-between h-16 px-4 py-5", role: "banner", children: [_jsx("a", { href: "https://pheasant.network/", className: "item pl-2 pr-8 py-2", children: _jsx("img", { src: isDarkMode ? logoDark : logo, alt: "Pheasant Network", width: "72px", height: "15.8px" }) }), _jsxs("div", { className: "flex justify-center items-center", children: [!isConnected && (_jsx("div", { children: _jsx("button", { className: "button w-full h-10 bg-primary-500 header-button relative mobile:px-4 dark:text-neutral-800", type: "button", onClick: () => setIsWalletModalOpen(true), children: _jsx("span", { className: "text-label_m whitespace-nowrap", children: "Connect Wallet" }) }) })), isConnected && (_jsxs("button", { className: `${isOpenHamburgerMenu ? 'z-10' : 'z-[60]'} h-10 flex justify-center items-center bg-neutral-0 dark:bg-secondary-900 dark:text-neutral-0 dark:border-neutral-700 border border-neutral-800 header-button text-clip mobile:px-3 mobile:py-2 w-full`, type: "button", onClick: () => {
                                            setShowMenu(true);
                                        }, "aria-label": 'Show "Account" menu', children: [connector ? (
                                            // connector.name === "WalletConnect" ? (
                                            //   walletImagePath && (
                                            //     <img
                                            //       className="h-6 inline"
                                            //       src={getImage(walletImagePath)}
                                            //       alt="Wallet Image"
                                            //       style={{ width: '1.25rem', height: '1.25rem' }}
                                            //     />
                                            //   )
                                            // ) : (
                                            _jsx("img", { className: "h-6 inline", src: getImage('wallet/' +
                                                    WALLETS.find((wallet) => wallet.name === connector.name)
                                                        .img), alt: "Wallet Image", style: { width: '20px', height: '20px' } })) : (
                                            //)
                                            _jsx(Loading, {})), _jsx("span", { className: "pl-2 text-label_m font-medium", children: shortenAddress(address, 4) })] })), showMenu && (_jsxs(_Fragment, { children: [_jsx("button", { onClick: () => {
                                                    setShowMenu(false);
                                                }, className: "z-50 cursor-pointer fixed w-full h-full modal-background top-0 left-0", "aria-label": 'Close "Account" menu' }), _jsx(AccountContent, {})] }))] }), _jsx("div", { className: "w-10 h-10 flex flex-wrap justify-center items-center rounded-8 bg-neutral-0 dark:bg-secondary-900 z-40", children: _jsx("button", { onClick: () => setIsOpenHamburgerMenu(!isOpenHamburgerMenu), "aria-label": "toggle header button", id: "mobile-hamburger-button", className: `cursor-pointer pointer-events-auto rounded-8 flex flex-wrap justify-center items-center w-8 h-8`, type: "button", children: _jsx("img", { src: isOpenHamburgerMenu
                                            ? isDarkMode
                                                ? closeDark
                                                : close
                                            : isDarkMode
                                                ? openDark
                                                : open, alt: "Hamburger Toggle Icon", style: {
                                            width: '18px',
                                            height: '12px',
                                        } }) }) })] }), isOpenHamburgerMenu && (_jsx(CSSTransition, { in: isOpenHamburgerMenu, timeout: 300, classNames: "slide", unmountOnExit: true, onExited: () => setIsOpenHamburgerMenu(false), children: _jsxs(_Fragment, { children: [_jsx("button", { onClick: () => setIsOpenHamburgerMenu(false), className: `cursor-pointer fixed w-full h-full z-30 modal-background top-0 left-0` }), _jsxs("div", { className: `absolute ${isVisibleNewsBanner ? 'top-[7.55rem]' : 'top-[4.313rem]'} right-0 w-64 z-40 bg-secondary-0 dark:bg-secondary-900 rounded-tl-16 rounded-bl-16 flex flex-col justify-between items-center`, style: { height: `${height}px` }, children: [_jsxs("div", { className: "w-full", children: [_jsx("div", { className: "flex flex-col items-center ml-auto px-4 pt-6 text-label_l font-medium", children: _jsx("div", { className: "flex justify-center items-center", children: _jsx(NetworkToggleButton, {}) }) }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px my-4" }), _jsxs("div", { className: "flex justify-between items-center px-4", onClick: () => {
                                                        navigate('/');
                                                    }, children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? bridgeDark : bridge, alt: "Bridge Icon", style: {
                                                                        width: '1.25rem',
                                                                        height: '1.25rem',
                                                                    }, "aria-label": "Bridge Icon" }), _jsx("nav", { role: "navigation", className: "text-label_l font-medium pl-1", children: "Bridge" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? internalArrowDark : internalArrow, alt: "Arrow Icon", style: {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                }, "aria-label": "Arrow Icon" }) })] }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px my-4" }), isMainnet ? (_jsxs("div", { className: "flex justify-between items-center px-4", onClick: () => {
                                                        navigate('/swap');
                                                    }, children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? bridgeDark : bridge, alt: "Swap Icon", style: {
                                                                        width: '1.25rem',
                                                                        height: '1.25rem',
                                                                    }, "aria-label": "Swap Icon" }), _jsx("nav", { role: "navigation", className: "text-label_l font-medium pl-1", children: "Swap" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? internalArrowDark : internalArrow, alt: "Arrow Icon", style: {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                }, "aria-label": "Arrow Icon" }) })] })) : (_jsxs("a", { href: "https://pheasant.network/swap", className: "flex justify-between items-center px-4", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? swapDark : swap, alt: "Swap Icon", style: {
                                                                        width: '1.25rem',
                                                                        height: '1.25rem',
                                                                    }, "aria-label": "Swap Icon" }), _jsx("nav", { role: "navigation", className: "text-label_l font-medium pl-1", children: "Swap" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? internalArrowDark : internalArrow, alt: "Arrow Icon", style: {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                }, "aria-label": "Arrow Icon" }) })] })), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px my-4" }), isConnected && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between items-center px-4", onClick: () => {
                                                                navigate('/history');
                                                            }, children: [_jsxs("div", { className: "flex items-center", children: [!hasProgressingTx && !hasProgressingSwapTx ? (_jsx("img", { src: isDarkMode ? historyDark : history, alt: "History Icon", style: {
                                                                                width: '1.25rem',
                                                                                height: '1.25rem',
                                                                            }, "aria-label": "History Icon" })) : (_jsx("div", { className: "loader-modal" })), _jsx("nav", { role: "navigation", className: "text-label_l font-medium pl-1", children: "History" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? internalArrowDark : internalArrow, alt: "Arrow Icon", style: {
                                                                            width: '20px',
                                                                            height: '20px',
                                                                        }, "aria-label": "Arrow Icon" }) })] }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px my-4" })] })), _jsxs("div", { className: "flex justify-between items-center px-4", onClick: () => {
                                                        window.location.href = 'https://l2learn.io/';
                                                    }, children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: l2Learn, alt: "L2 learn Icon", style: {
                                                                        width: '1.25rem',
                                                                        height: '1.25rem',
                                                                    }, "aria-label": "L2 learn Icon" }), _jsx("nav", { role: "navigation", className: "text-label_l font-medium pl-1", children: "L2 Learn" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? internalArrowDark : internalArrow, alt: "Arrow Icon", style: {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                }, "aria-label": "Arrow Icon" }) })] }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px my-4" }), _jsxs("div", { className: "flex justify-between items-center px-4", onClick: () => {
                                                        window.open('https://home.pheasant.network', '_blank');
                                                    }, children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? homeDark : home, alt: "Home Icon", style: {
                                                                        width: '1.25rem',
                                                                        height: '1.25rem',
                                                                    }, "aria-label": "Home Icon" }), _jsx("nav", { role: "navigation", className: "text-label_l font-medium pl-1", children: "Home" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "Arrow Icon", style: {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                }, "aria-label": "Arrow Icon" }) })] }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px my-4" }), _jsx("div", { className: "flex flex-col justify-between items-center", children: _jsx(DarkModeToggleButton, {}) })] }), _jsx("div", { className: "w-full pb-10", children: _jsx(SnsNav, {}) })] })] }) }))] }), _jsx("header", { className: "header px-8", role: "banner", children: _jsxs("div", { className: "inner-content inner-header", children: [_jsx("a", { href: "https://pheasant.network/", className: "mr-8", children: _jsx("img", { src: isDarkMode ? logoDark : logo, alt: "Pheasant Network", width: "120px", height: "27px" }) }), _jsxs("nav", { className: "flex justify-center items-center", role: "navigation", children: [_jsx(Link, { to: "/", className: `${location.pathname === '/'
                                        ? 'active text-neutral-800 dark:text-neutral-0'
                                        : 'text-neutral-600 dark:text-neutral-200'} link text-label_l font-medium hover:font-semibold`, children: "Bridge" }), isMainnet ? (_jsx(Link, { to: "/swap", className: `${location.pathname === '/swap'
                                        ? 'active text-neutral-800 dark:text-neutral-0'
                                        : 'text-neutral-600 dark:text-neutral-200'} link text-label_l font-medium hover:font-semibold`, children: "Swap" })) : (_jsx("a", { href: "https://pheasant.network/swap", className: "link text-label_l font-medium hover:font-semibold text-neutral-600 dark:text-neutral-200", children: "Swap" })), _jsx("a", { href: "https://l2learn.io/", className: "link text-label_l font-medium hover:font-semibold text-neutral-600 dark:text-neutral-200", children: "L2 Learn" })] }), _jsx("div", { className: "header-items ml-auto px-4 py-5", children: _jsx(HeaderButtons, { isWalletModalOpen: isWalletModalOpen, setIsWalletModalOpen: setIsWalletModalOpen }) }), _jsx("div", { className: "w-11 h-11 flex flex-wrap justify-center items-center rounded-8 bg-neutral-0 dark:bg-secondary-900 z-40", children: _jsx("button", { ref: menuButtonRef, onClick: () => setIsOpenHamburgerMenu(!isOpenHamburgerMenu), "aria-label": "toggle header button", className: `cursor-pointer pointer-events-auto rounded-8 flex flex-wrap justify-center items-center w-9 h-9`, type: "button", children: _jsx("img", { src: isOpenHamburgerMenu
                                        ? isDarkMode
                                            ? closeDark
                                            : close
                                        : isDarkMode
                                            ? openDark
                                            : open, alt: "Hamburger Toggle Icon", style: {
                                        width: '18px',
                                        height: '12px',
                                    } }) }) }), isOpenHamburgerMenu && (_jsx(CSSTransition, { in: isOpenHamburgerMenu, timeout: 300, classNames: "slide", unmountOnExit: true, onExited: () => setIsOpenHamburgerMenu(false), children: _jsxs(_Fragment, { children: [_jsx("button", { onClick: () => setIsOpenHamburgerMenu(false), className: `cursor-pointer fixed w-full h-full z-30 modal-background top-0 left-0` }), _jsx("div", { className: `absolute ${isVisibleNewsBanner ? 'top-[7.55rem]' : 'top-[4.313rem]'} w-64 z-40 bg-secondary-0 dark:bg-secondary-900 rounded-16 flex flex-col justify-between items-center`, style: { right: `${menuRightPosition}px` }, children: _jsxs("div", { className: "w-full", children: [_jsx("div", { className: "flex flex-col items-center ml-auto px-4 pt-6", children: _jsx("div", { className: "flex justify-center items-center", children: _jsx(NetworkToggleButton, {}) }) }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px mt-4" }), _jsxs("div", { className: "flex justify-between items-center p-4 hover:bg-secondary-10 dark:hover:bg-secondary-800 cursor-pointer text-label_l font-medium hover:font-semibold", onClick: () => {
                                                        window.open('https://home.pheasant.network', '_blank');
                                                    }, children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? homeDark : home, alt: "Home Icon", style: {
                                                                        width: '1.25rem',
                                                                        height: '1.25rem',
                                                                    }, "aria-label": "Home Icon" }), _jsx("nav", { role: "navigation", className: "pl-1", children: "Home" })] }), _jsx("div", { children: _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "Arrow Icon", style: {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                }, "aria-label": "Arrow Icon" }) })] }), _jsx("hr", { className: "bg-neutral-100 dark:bg-neutral-700 border-none h-px mb-4" }), _jsx("div", { className: "flex flex-col justify-between items-center pb-6", children: _jsx(DarkModeToggleButton, {}) })] }) })] }) }))] }) }), _jsx(WalletModal, { isWalletModalOpen: isWalletModalOpen, setIsWalletModalOpen: setIsWalletModalOpen })] }));
});
export default Header;
