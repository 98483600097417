import { getChains } from '@wagmi/core';
import { wagmiConfig, getWagmiConfig } from '@/wagmiConfig';
import { isHealthyRpcWithBalance, getHealthyRpcWithBalance, getRpcUrls, } from '@pheasant-network/pheasant-sdk';
export const getHealthyWagmiConfig = async (chainId, address, rpcUrls) => {
    let newChain = getChains(wagmiConfig).find((c) => c.id === chainId);
    const isHealthy = await isHealthyRpcWithBalance(newChain.rpcUrls.default.http[0], address);
    console.log(`${newChain.rpcUrls.default.http[0]} is ${isHealthy ? 'healthy' : 'unhealthy'}`);
    if (!isHealthy) {
        try {
            newChain = await updateRpcUrls([rpcUrls, (await getRpcUrls(chainId.toString())).rpc], newChain, address);
            return getWagmiConfig(newChain);
        }
        catch (err) {
            console.error(err);
        }
    }
    return getWagmiConfig();
};
const updateRpcUrls = async (setOfRpcUrls, chain, address) => {
    if (setOfRpcUrls && setOfRpcUrls.length === 0) {
        throw new Error(`Failed to find healthy rpc on chainId: ${chain.id}`);
    }
    const rpcUrls = setOfRpcUrls.pop();
    if (rpcUrls && rpcUrls.length === 0) {
        throw new Error(`Failed to find healthy rpc on chainId: ${chain.id}`);
    }
    try {
        const healthyRpc = await getHealthyRpcWithBalance(rpcUrls, address);
        chain.rpcUrls = {
            ...chain.rpcUrls,
            default: {
                http: [healthyRpc],
            },
        };
        return chain;
    }
    catch (err) {
        return await updateRpcUrls(setOfRpcUrls, chain, address);
    }
};
