import { jsx as _jsx } from "react/jsx-runtime";
// react
import { createContext, useContext, useReducer, useMemo, } from 'react';
// constants
import { constants } from '@/constants';
export var TradeType;
(function (TradeType) {
    TradeType[TradeType["UPWARD"] = 0] = "UPWARD";
    TradeType[TradeType["DOWNWARD"] = 1] = "DOWNWARD";
    TradeType[TradeType["CCTP"] = 2] = "CCTP";
})(TradeType || (TradeType = {}));
const initialState = {
    txList: [],
    processingTx: null,
};
const txInfoReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TX': {
            const newTxList = [...state.txList, action.payload];
            return {
                ...state,
                txList: newTxList,
                processingTx: action.payload,
            };
        }
        case 'ADD_ALL_TX': {
            const newTxList = [...state.txList, ...action.payload];
            return {
                ...state,
                txList: newTxList,
            };
        }
        case 'REMOVE_TX':
            return {
                ...state,
                txList: state.txList.filter((tx) => tx.fromTxhash !== action.payload),
            };
        case 'REMOVE_PROCESSING':
            const updatedTxList = state.txList.map((tx) => {
                tx.isProcessing = false;
                tx.isTracking = false;
                return tx;
            });
            return {
                ...state,
                txList: [...updatedTxList],
                processingTx: null,
            };
        case 'REMOVE_COMPLETED':
            return {
                ...state,
                txList: state.txList.filter((tx) => tx.status !== constants.DB_STATUS_COMPLETED),
            };
        case 'CLEAR_ALL_TX':
            return {
                ...state,
                txList: [],
            };
        case 'UPDATE_TX_INFO': {
            let updatedTx = null;
            const updatedTxList = state.txList.map((tx) => {
                if (tx.fromTxhash === action.payload.fromTxhash) {
                    updatedTx = { ...tx, ...action.payload.updates };
                    return updatedTx;
                }
                return tx;
            });
            return {
                ...state,
                txList: [...updatedTxList],
                processingTx: state.processingTx &&
                    state.processingTx.fromTxhash === action.payload.fromTxhash
                    ? updatedTx
                    : null,
            };
        }
        default:
            return state;
    }
};
const CurrentSwapTxInfoContext = createContext(undefined);
export const CurrentSwapTxInfoProvider = ({ children, }) => {
    const [state, dispatch] = useReducer(txInfoReducer, initialState);
    const addTx = (tx) => {
        const newTx = {
            ...tx,
            type: 'Swap',
            date: String(new Date().getTime()),
        };
        dispatch({ type: 'ADD_TX', payload: newTx });
    };
    const addAllTx = (txs) => {
        const newTxs = txs.map((tx) => {
            return {
                ...tx,
                type: 'Swap',
            };
        });
        dispatch({ type: 'ADD_ALL_TX', payload: newTxs });
    };
    const removeTx = (fromTxhash) => {
        dispatch({ type: 'REMOVE_TX', payload: fromTxhash });
    };
    const removeProcessing = () => {
        dispatch({ type: 'REMOVE_PROCESSING' });
    };
    const removeCompletedTx = () => {
        dispatch({ type: 'REMOVE_COMPLETED' });
    };
    const clearAllTx = () => {
        dispatch({ type: 'CLEAR_ALL_TX' });
    };
    const updateTxInfo = (fromTxhash, updates) => {
        dispatch({ type: 'UPDATE_TX_INFO', payload: { fromTxhash, updates } });
    };
    const getTxByHash = (txHash) => {
        return state.txList.find((tx) => tx.fromTxhash === txHash);
    };
    const hasProgressingTx = useMemo(() => {
        return state.txList.some((tx) => tx.status === constants.DB_STATUS_IN_PROGRESS);
    }, [state.txList]);
    return (_jsx(CurrentSwapTxInfoContext.Provider, { value: {
            txList: state.txList,
            processingTx: state.processingTx,
            addTx,
            addAllTx,
            removeTx,
            removeProcessing,
            removeCompletedTx,
            clearAllTx,
            updateTxInfo,
            getTxByHash,
            hasProgressingTx,
        }, children: children }));
};
export const useCurrentSwapTxInfo = () => {
    const context = useContext(CurrentSwapTxInfoContext);
    if (!context) {
        throw new Error('useCurrentSwapTxInfo must be used within a CurrentSwapTxInfoProvider');
    }
    return context;
};
