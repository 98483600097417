import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Transition } from 'react-transition-group';
import { PhaseType, usePhaseContext, useIsDarkModeContext } from '@/context';
import WarningTippy from '@/domain/main/WarningTippy';
import close from '@/assets/new-icon/close.svg';
import closeDark from '@/assets/new-icon/close-dark.svg';
import closePhase2 from '@/assets/new-icon/close-phase2.svg';
import { isMobileDevice } from '@/utils';
import { memo } from 'react';
const SimpleModal = memo(({ title, children, onClose, className = '', isOpen, shouldTransition = true, }) => {
    const { phase } = usePhaseContext();
    const { isDarkMode } = useIsDarkModeContext();
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    const ModalBody = () => (_jsxs(_Fragment, { children: [_jsx("button", { onClick: onClose, className: "cursor-pointer fixed w-full h-full modal-background z-[60] top-0 left-0", disabled: phase === PhaseType.APPROVED }), _jsxs("div", { className: `window fixed z-[70] w-[600px] bg-neutral-0 dark:bg-secondary-900 p-6 mobile:py-6 mobile:px-4 rounded-16 flex flex-col border border-black dark:border-custom-black`, children: [_jsxs("div", { className: "relative w-full flex justify-between items-center dark:text-neutral-0", children: [_jsx("h2", { className: `absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4 whitespace-pb-8 nowrap rounded-full ${isMobileDevice() ? 'text-h1_modal-mobile' : 'text-h1_modal'} py-2 px-4 text-center`, children: title }), phase !== PhaseType.APPROVED && (_jsx("button", { onClick: onClose, "aria-label": "close modal", className: "flex justify-end items-center w-full", children: _jsx("img", { src: isDarkMode ? closeDark : close, alt: "arrow", className: "inline ml-1", width: "24px", height: "24px" }) })), phase === PhaseType.APPROVED && (_jsx(WarningTippy, { tooltipContent: _jsx("div", { children: _jsx("p", { className: "typo text-justify items-center text-paragraph_m font-normal p-4 text-error-100", children: "\u26A0\uFE0F To secure your transaction, the modal cannot be closed until you sign in your wallet." }) }), children: _jsx("img", { src: closePhase2, alt: "arrow", className: "inline ml-1", width: "24px", height: "24px" }) }))] }), children] })] }));
    const ModalContent = shouldTransition ? (_jsx(Transition, { in: isOpen, timeout: 300, appear: true, unmountOnExit: true, children: (state) => (_jsx("div", { style: {
                ...transitionStyles[state],
                transition: 'opacity 300ms ease-in-out',
            }, className: `font-display flex justify-center items-center fixed top-0 left-0 w-full h-full z-[60] ${className}`, children: _jsx(ModalBody, {}) })) })) : (isOpen && (_jsx("div", { style: {
            opacity: 1,
        }, className: `font-display flex justify-center items-center fixed top-0 left-0 w-full h-full z-[60] ${className}`, children: _jsx(ModalBody, {}) })));
    return ModalContent;
});
export default SimpleModal;
