import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
const Teleport = memo(({ children }) => {
    const containerRef = useRef(null);
    if (!containerRef.current) {
        containerRef.current = document.createElement('div');
    }
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            document.body.appendChild(container);
        }
        return () => {
            if (container) {
                document.body.removeChild(container);
            }
        };
    }, []);
    return containerRef.current
        ? ReactDOM.createPortal(children, containerRef.current)
        : null;
});
export default Teleport;
